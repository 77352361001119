<template>
  <div v-if="selectedCurso">
    <BOverlay
      :show="spinner"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <cursosForm
        btnSubmit="Editar Curso"
        :curso="selectedCurso"
        @processForm="editar"
      />
    </BOverlay>
  </div>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import cursosForm from './CursosForm.vue'

export default {
  components: {
    cursosForm,
    BOverlay,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('cursos', ['selectedCurso']),
  },
  methods: {
    ...mapActions({ updateCurso: 'cursos/updateCurso' }),
    editar(curso) {
      this.spinner = true
      this.updateCurso(curso).then(() => {
        const errorCursos = store.state.cursos
        const errorMessage = errorCursos.errorMessage.errors
        if (!errorCursos.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Curso editado 👍',
              text: `El curso "${curso.nombre}" fue actualizado con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 3000,
          })
          this.$router.replace({
            name: 'cursos',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorCursos.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
